import React, { useEffect, useState, Component } from "react";
import { Button, Card, Col, Row, Nav } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import { Doughnut } from 'react-chartjs-2';
import UserManager from "../../functions/SessionManager";
import formsManager from "../../hooks/formsManager";
import "../../assets/css/animations.css";

export default function DatabaseManagement( props ) {
  const { setScreen, setDBMOptions } = props;
  const [DataExample, SetDataExample] = useState(null);
  const [TamañoTotal, SetTamañoTotal] = useState(0);
  const [ConteoTotal, SetConteoTotal] = useState(0);
  const [dataDonut, SetDataDonut] = useState(null);

  if (!UserManager.isAllowed()){
    console.log("Not conected");
    setScreen("NotConected");
  }

  formsManager.GetTemporalData(SetDataExample, SetTamañoTotal, SetConteoTotal, SetDataDonut);

  const optionDonut = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      }
    },
    animation: {
      animateScale: true,
      animateRotate: true
    }
  };

  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return DataExample? (
    <React.Fragment>
        <Row className="g-3">
          {DataExample.map((x, i) => {
            return <DatabaseDocumentData data={x} setScreen={setScreen} setDBMOptions={setDBMOptions} key={"DatabaseManagement-item-"+i}/>
          })}
          <Col md="7" lg="6" xl="4">
            <Card className="card-one">
              <Card.Body className="p-4">
                <div className="chart-donut-three mb-4">
                  {dataDonut? <Doughnut data={dataDonut} options={optionDonut} /> : ""}
                </div>
                <Row className="g-3">
                  <Col xs="6">
                    <label className="d-block mb-1">Espacio total</label>
                    <h2 className="card-value ls--1 mb-0">{TamañoTotal/1000}<span>kB</span></h2>
                  </Col>
                  <Col xs="6">
                    <label className="d-block mb-1">Cantidad de Archivos</label>
                    <h2 className="card-value ls--1 mb-0">{ConteoTotal}</h2>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      {/*</div>*/}
    </React.Fragment>
  )
  :
  ""
};


class DatabaseDocumentData extends Component {
  DataTemporal = [];
  ColectionSize;
  ColectionCount;
  ColectionName;

  states = {
    hover: {
      filter: {
        type: 'none'
      }
    },
    active: {
      filter: {
        type: 'none'
      }
    }
  };

  optionOne = {
    chart: {
      parentHeightOffset: 0,
      stacked: true,
      toolbar: { show: true }
    },
    grid: {
      borderColor: 'rgba(72,94,144, 0.07)',
      padding: {
        top: -20,
        left: 0,
        right: 0,
        bottom: 0
      },
      yaxis: {
        lines: { show: true },
      }
    },
    colors: ['#506fd9', '#c8ccd4'],
    plotOptions: {
      bar: { columnWidth: '90%' }
    },
    stroke: {
      curve: 'straight',
      lineCap: 'square',
      width: 0
    },
    xaxis: {
      min: 0,
      type: 'numeric',
      tickAmount: 6,
      decimalsInFloat: 0,
      labels: {
        style: { fontSize: '11px' }
      }
    },
    yaxis: {
      show: false
    },
    states: this.states,
    dataLabels: { enabled: false },
    tooltip: { enabled: true },
    fill: { opacity: 1 },
    legend: { show: false }
  };

  constructor(props) {
    const { data, setScreen, setDBMOptions } = props;
    var date = new Date();
    super();
    this.DataTemporal = data.TemporalData;
    this.ColectionCount = data.ColectionCount;
    this.ColectionSize = data.ColectionSize/1000;
    this.ColectionName = data.ColectionName;

    this.state = { 
      SelectedYear: date.getFullYear(),
      SelectedMonth: date.getMonth(),
      CompleteTable: [],
      setScreen: setScreen,
      setDBMOptions: setDBMOptions
    };

    this.UpdateTable();
  }

  async componentDidMount() {
    this.UpdateTable();
  }

  UpdateTable() {
    const { 
      SelectedYear,
      SelectedMonth,
      CompleteTable,
      setScreen,
      setDBMOptions
    } = this.state;

    var date = new Date();
    var TempTable = [];

    if (SelectedMonth == date.getMonth() && SelectedYear == date.getFullYear()){
      var CantDias = date.getDate();
      var num = [...Array(CantDias).keys()];
      for (let i in num){
        let DataAct = this.DataTemporal.find((e) => 
          {
            return e.year == SelectedYear && e.month == SelectedMonth && Number(e.day) == Number(i)+1;
          }
        );
        TempTable.push(DataAct == undefined? [Number(i)+1, 0] : [Number(i)+1, DataAct.value]);
      }
    }else {
      var CantDias = new Date(SelectedYear, SelectedMonth, 0).getDate();
      var num = [...Array(CantDias).keys()];
      for (let i in num){
        const DataAct = this.DataTemporal.find((e) => 
          {
            return e.year == SelectedYear && Number(e.month) == Number(SelectedMonth) && Number(e.day) == Number(i)+1;
          }
        );
        TempTable.push(DataAct == undefined? [Number(i)+1, 0] : [Number(i)+1, DataAct.value]);
      }
    }

    this.setState({
      SelectedYear: SelectedYear,
      SelectedMonth: SelectedMonth,
      CompleteTable: TempTable,
      setScreen: setScreen,
      setDBMOptions: setDBMOptions
    });
  }

  PrevMonth() {
    const { 
      SelectedYear,
      SelectedMonth,
      CompleteTable,
      setScreen,
      setDBMOptions
    } = this.state;

    let MesAct = SelectedMonth;
    let AñoAct = SelectedYear;

    MesAct = MesAct-1;
    if (MesAct < 0){
      AñoAct = AñoAct-1;
      MesAct = 11;
    }

    this.setState({
      SelectedYear: AñoAct,
      SelectedMonth: MesAct,
      CompleteTable: CompleteTable,
      setScreen: setScreen,
      setDBMOptions: setDBMOptions
    }, () => {this.UpdateTable()});
    
    //this.UpdateTable();
  }

  NextMonth() {
    const { 
      SelectedYear,
      SelectedMonth,
      CompleteTable,
      setScreen,
      setDBMOptions
    } = this.state;

    let MesAct = SelectedMonth;
    let AñoAct = SelectedYear;

    MesAct = MesAct+1;
    if (MesAct > 11){
      AñoAct = AñoAct+1;
      MesAct = 0;
    }

    this.setState({
      SelectedYear: AñoAct,
      SelectedMonth: MesAct,
      CompleteTable: CompleteTable,
      setScreen: setScreen,
      setDBMOptions: setDBMOptions
    }, () => {this.UpdateTable()});
  }

  ManejarDocClick() {
    const { 
      SelectedYear,
      SelectedMonth,
      CompleteTable,
      setScreen,
      setDBMOptions
    } = this.state;
    setDBMOptions({
      DBName: this.ColectionName,
    })
    setScreen("ManejoDBSpec");
  }

  render() {
      const { 
        SelectedYear,
        SelectedMonth,
        CompleteTable,
        setScreen,
        setDBMOptions
      } = this.state;
      const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
      return (
      <>
        <Col xs="12" sm="6" xl="2">
          <Card className="card-one">
            <Card.Body className="p-3 d-flex flex-column align-items-center justify-content-around">
              <div className="mb-1 text-primary ti--3"><i className="ri-file-text-line fs-48 lh-1"></i></div>
              <div className="mb-1">
                  <h6 className="fw-semibold text-dark mb-1">{this.ColectionName}</h6>
                  <p className="fs-xs mb-0 text-secondary"><span className="ff-numerals">{this.ColectionCount}</span> Archivos</p>
                  <div className="d-flex justify-content-between ff-numerals fs-xs fw-medium">
                  <span>{this.ColectionSize} kB</span>
                  </div>
              </div>
              <Button onClick={(e) => {this.ManejarDocClick(e)}}>Manejar documento</Button>
            </Card.Body>
          </Card>
        </Col>
        <Col xl="10">
          <Card className="card-one">
            <Card.Header>
              <Card.Title as="h6">Informacion {this.ColectionName}</Card.Title>
              <Nav className="nav-icon nav-icon-sm ms-auto">
                <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
              </Nav>
            </Card.Header>
            <Card.Body className="p-3">
              <Row className="g-3">
                <Col sm="3" md="2" className="d-flex flex-column justify-content-around">
                  <Row className="g-3">
                    <Col xs="4" sm="4" className="hover-animation-up transition-2">
                      <i className="ri-arrow-drop-left-line fs-48 lh-1" onClick={(e) => {this.PrevMonth()}}></i>
                    </Col>
                    <Col xs="4" sm="4" className="d-flex flex-column align-items-center">
                      <h2 className="card-value ls--1 mb-1">{SelectedYear}</h2>
                      <label className="card-label fw-medium text-secondary fs-sm fs-sm-normal">{months[SelectedMonth]}</label>
                    </Col>
                    <Col xs="4" sm="4" className="hover-animation-up transition-2">
                      <i className="ri-arrow-drop-right-line fs-48 lh-1" onClick={(e) => {this.NextMonth()}}></i>
                    </Col>
                  </Row>
                </Col>
                <Col sm="9" md="10">
                  <ReactApexChart series={[{data: CompleteTable}]} options={this.optionOne} type="bar" height={200} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </>
      )
  }
}