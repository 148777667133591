import axios from 'axios';

const UserManager = (function() {
    var UserName = "";
    var Allowed = false;
    var SesionID = "";
    var Listeners = [];

    const Start = () => {
        var GetSesID = document.cookie;

        console.log("Iniciando");

        if (GetSesID != undefined){
            var CookieList = GetSesID.split(";");
            var SesID = CookieList.find((x) => {return x.includes("SesID")});
            if (SesID != undefined){
                SesionID = SesID.split("=")[1];
            }
        }
        Verificacion();
    }

    const Verificacion = () => {
        console.log("Pidiendo verificacion");
        ServerVerify();
        setTimeout(() => {Verificacion()}, 3600000);
    }

    const ServerVerify = async () => {
        const url = process.env.REACT_APP_API_URL+'users/isAuth/';

        axios.get(url, {params : {UserID: SesionID}}).then((response) => {
            if (response.data.authorized){
                Allowed = true;
            }else{
                SesionID = "";
                UserName = "";
                Allowed = false;
            }
            Notificar();
        });
    }

    const TestSesion = async (User, Password) => {
        const url1 = process.env.REACT_APP_API_URL+'users/auth';
        const formData1 = new FormData();

        UserName = User;

        formData1.append('userName', User);
        formData1.append('password', Password);
        
        let response1 = await fetch(url1, {
            mode: "cors",
            method: 'POST',
            body: formData1,
        })
      
        const Confirmation = await response1.json();

        if (Confirmation.isAuth === true) {
            SesionID = Confirmation.UserID;
            document.cookie = "SesID="+SesionID+"; path=/";
            Allowed = true;
        }else{
            Allowed = false;
        }
        
        Notificar();
        
        return Allowed;
    };

    const isAllowed = function() {
        return Allowed;
    };
  
    const getName = function() {
        return UserName;
    };
    
    const Suscribirse = function(listener) {
        Listeners.push(listener);
    };
    
    const Notificar = function() {
        console.log("Notificando");
        Listeners.forEach((listen) => {listen()});
    }

    const GetID = function() {
        return SesionID;
    }
    
    return {
      Start: Start,
      getUser: getName,
      isAllowed: isAllowed,
      TestSesion: TestSesion,
      Suscribirse: Suscribirse,
      getID: GetID
    }
})();
  
  export default UserManager;