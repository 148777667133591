import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { Accordion, Card, Button } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import userAvatar from "../assets/img/img1.jpg";
import UserManager from "../functions/SessionManager";
import background from "./img/static/UA.png";
import { MyEstadoGlobalContext } from './MyEstadoGlobalContext';

import {
    dashboardMenu,
    pagesMenu,
    InicioMenu
} from "../data/Menu";

export default function Sidebar() {
    // Acceder al estado global de mostrarWSE con useContext
    const { mostrarWSE, setMostrarWSE } = useContext(MyEstadoGlobalContext);

    const toggleFooterMenu = (e) => {
        e.preventDefault();
        let parent = e.target.closest(".sidebar");
        parent.classList.toggle("footer-menu-show");
    };

    const toggleMenu = (e) => {
        e.preventDefault();
        let parent = e.target.closest('.nav-group');
        parent.classList.toggle('show');
    };

    const toggleSubMenu = (e) => {
        e.preventDefault();
        let parent = e.target.closest('.nav-item');
        let node = parent.parentNode.firstChild;

        while (node) {
            if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
                node.classList.remove('show');
            node = node.nextElementSibling || node.nextSibling;
        }

        parent.classList.toggle('show');
    };

    const populateMenu = (m) => {
        const menu = m.map((m, key) => {
            let sm;
            if (m.submenu) {
                sm = m.submenu.map((sm, key) => {
                    return (
                        <NavLink to={sm.link} className="nav-sub-link" key={key}>{sm.label}</NavLink>
                    )
                });
            }

            return (
                <li key={key} className="nav-item">
                    {(!sm) ? (
                        <NavLink to={m.link} className="nav-link" onClick={() => setMostrarWSE(false)}>
                            <i className={m.icon}></i> <span>{m.label}</span>
                        </NavLink>
                    ) : (
                        <div onClick={toggleSubMenu} className="nav-link has-sub">
                            <i className={m.icon}></i> <span>{m.label}</span>
                        </div>
                    )}
                    {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
                </li>
            )
        });

        return (
            <ul className="nav nav-sidebar">
                {menu}
            </ul>
        );
    };

    return (
        <div className="sidebar">
            <div className="sidebar-header">
                <img src={background} width="100" height="50" alt="UA logo" />
                <Link to="/home" className="sidebar-logo"> Observatorio Prometea </Link>
            </div>
            <PerfectScrollbar className="sidebar-body">
                <div className="nav-group show">
                    <div className="nav-label" onClick={toggleMenu}>Inicio</div>
                    {populateMenu(InicioMenu)}
                </div>
                <div className="nav-group show">
                    <div className="nav-label" onClick={toggleMenu}>Dashboard</div>
                    {populateMenu(dashboardMenu)}
                </div>
                <div className="nav-group show">
                    <div className="nav-label" onClick={toggleMenu}>Administración</div>
                    {populateMenu(pagesMenu)}
                </div>
            </PerfectScrollbar>
        </div>
    );
}
