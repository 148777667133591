import React from 'react';
const MyEstadoGlobalContext = React.createContext(
    { mostrarBD: false, setMostrarBD: () => {} },
    { mostrarWSE: false, setMostrarWSE: () => {} },
    { mostrarProyectos: false, setMostrarProyectos: () => {} },
    { mostrarPI: false, setMostrarPI: () => {} },
    { mostrarHome: true, setMostrarHome: () => {} },
    { mostrarAyuda: false, setMostrarAyuda: () => {} },
    { mostraIngreso: false, setMostrarIngreso: () => {} }
    );
export {MyEstadoGlobalContext};
