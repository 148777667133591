import DinamicElement from "../DinamicElement";

//Graficos secundarios
const Secondary = (props) => {
    const de  = {elementType:"RowCharts", data: props.selectedChart}

    return ( props.selectedChart===null ?
        "":
        <>
         <DinamicElement dinamicContent={de} /> 
        </>
     );

}
 
export default Secondary;
