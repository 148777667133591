import React from "react";
import { Button, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function NotificationScreen( props ) {
  const { setScreen, Notification } = props;

  const VolverAOperacion = () => {
    setScreen("ManejoDBSpec");
  }

  return (
    <div className="d-flex align-items-center justify-content-center">
      <Card className="card-sign">
        <Card.Header className="d-flex flex-column align-items-center">
          <Link to="/" className="header-logo mb-4">Observatorio Prometea</Link>
          <Card.Title className="text-center">
            {Notification.Status >= 200 && Notification.Status < 300 ? "La operacion se efectuo correctamente" : "La operacion fallo" }
          </Card.Title>
          <Card.Text className="text-center">
            {Notification.Error}
          </Card.Text>
        </Card.Header>
        <Card.Body className="d-flex flex-column align-items-center">
          <Button variant="primary" className="btn-sign" onClick={() => {VolverAOperacion()}}>Volver</Button>
        </Card.Body>
      </Card>
    </div>
  )
}