import { useState } from 'react';
import {Container} from 'react-bootstrap';
import Secondary from '../pages/Secondary';
import DinamicElement from '../DinamicElement';

//Pagina con los graficos
const Page = (props) => {
    const [selectedChart, setSelectedChart] = useState(null);

    return ( 
        <Container>
            {props.id}
            {props.label}
            {props.descrip}             
            {
            props.elements.map((element) =>
                    (element.elementType==="Secondary")?
                        <Secondary selectedChart={selectedChart} />
                        :
                        <DinamicElement updateSelectedChart={setSelectedChart} dinamicContent={element}  />
                )
            } 
        </Container>

     );
}
 
export default Page;