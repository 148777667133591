import { useEffect } from 'react';
import axios from 'axios';
import UserManager from '../functions/SessionManager';

const formsManager = (function() {
    
    const jsonToCSV = (datosJSON) => {
        const encabezados = Object.keys(datosJSON[0]);
        const filas = datosJSON.map(obj => encabezados.map(encabezado => obj[encabezado]));
        let csv = encabezados.join(';') + '\n';
        csv += filas.map(fila => fila.join(';')).join('\n');
        return csv;
    }

    const GetBase = async ( setMarcas ) => {
        const url = process.env.REACT_APP_API_URL+ "forms/";
    
        useEffect(() => {
            axios.get(url, {params : {UserID: UserManager.getID()}}).then((response) => {
                setMarcas(response.data.map((x) => {
                    return {"_id":x._id, "filename":x.filename,"filesize":(x.filesize/1000)+" kb","selected":false}
                }));
            });
        }, []);
    }

    const GetEspecificData = async ( dataID, filename ) => {
        const url = process.env.REACT_APP_API_URL+ "forms/" + dataID;
    
        axios.get(url, {params : {UserID: UserManager.getID()}}).then((response) => {

            var datos = response.data;
            for (var i = 0; i < datos.length; i++){
                delete datos[i]["_id"];
                delete datos[i]["type"];
            }


            const CSV = jsonToCSV(datos);
            const elemento = document.createElement('a');
            elemento.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(CSV));
            elemento.setAttribute('download', filename+'.csv');
            elemento.style.display = 'none';
            document.body.appendChild(elemento);
            elemento.click();
            document.body.removeChild(elemento); 
        });
    }

    const GetTemporalData = async (SetDataExample, SetTamañoTotal, SetConteoTotal, SetDataDonut) => {
        const url = process.env.REACT_APP_API_URL+"forms/temporaldata/";

        useEffect(() => {
            axios.get(url, {params : {UserID: UserManager.getID()}}).then((response) => {
                SetDataExample(response.data);
                SetTamañoTotal(response.data.reduce((total, val) => {return total + val.ColectionSize}, 0));
                SetConteoTotal(response.data.reduce((total, val) => {return total + val.ColectionCount}, 0));
                SetDataDonut({
                    labels: response.data.map((x) => {return x.ColectionName}),
                    datasets: [{
                    data: response.data.map((x) => {return x.ColectionSize}),
                    },{
                    data: response.data.map((x) => {return x.ColectionCount}),
                    }]
                });
            });
        }, []);
    }

    const GetSpecTemporalData = async (SetDataExample, DataName) => {
        const url = process.env.REACT_APP_API_URL+"forms/temporaldata/";

        useEffect(() => {
            axios.get(url, {params : {UserID: UserManager.getID()}}).then((response) => {
                SetDataExample(response.data.find((x) => {
                    return x.ColectionName === DataName;
                }));
            });
        }, []);
    }
    
    return {
      GetBase: GetBase,
      GetEspecificData: GetEspecificData,
      GetTemporalData: GetTemporalData,
      GetSpecTemporalData: GetSpecTemporalData
    }
})();
  
  export default formsManager;