import React, { useState, useEffect, useRef } from "react";
import useFetch from "react-fetch-hook";
import ddStyles from "./NavBarMenu.module.css";
import { Link } from "react-router-dom"
import NavBarSubMenu from "../NavBarSubMenu";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
function NavBarMenu(props) {

  const { dropdownTitle, SetChart } = props;

  const activatorRef = useRef(null);
  const dropdownListRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const [datos, setData] = useState([]);
  const [IsLoad, setIsLoad] = useState(false);

  let menus = ["Investigador(a) Responsable", "Participación", "Co-Investigador(a)", "Proyectos adjudicados"]

  
  console.log("Probando...")
  console.log(process.env.REACT_APP_API_URL)
    //Petición HTTP para obtener todos los charts
  const { isLoading,data,error } = useFetch(process.env.REACT_APP_API_URL + "charts/", {
        method: "GET",
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        }
    });

    if (error) {
      /*   return <div>
          <p>Code: {error.status}</p>
          <p>Message: {error.statusText}</p>
        </div> */
        alert('Prometea no encontro resultados de la búsqueda. (a continuación un ejemplo de resultados ...')
        //default:
  
      }
      // console.log("isLoad : " + IsLoad)
      // console.log("isLoading : " + isLoading)
    //Verifica que la petición haya retornado y que los datos no se hayan cargado en el estado
  if(isLoading == false && IsLoad == false)
    {
        setData(data)
        setIsLoad(true)
        console.log("isLoading == false")
    };

    console.log("--- : "+data)

  /*  //Para modificar el estado de la query
  const ClickBoton = (e) => {
    SetPantalla(e);
  };*/

  //Para abrir y cerrar el menú
  const clickHandler = () => {
    setIsOpen(!isOpen);
  };

 //Para cerrar el menú con la tecla escape
  const keyHandler = event => {
    if (event.key === "Escape" && isOpen) {
      setIsOpen(false);
    }
  };

  //Para cerrar el menú con un click fuera del cuadro
  const clickOutsideHandler = event => {
    if (dropdownListRef.current) {
      if (
        dropdownListRef.current.contains(event.target) ||
        activatorRef.current.contains(event.target)
      ) {
        return;
      }

      setIsOpen(false);
    }
  };


  useEffect(() => {
    if (isOpen) {
      //dropdownListRef.current.querySelector("a").focus();
      document.addEventListener("mousedown", clickOutsideHandler);
    } else {
      document.addEventListener("mousedown", clickOutsideHandler);
    }
  }, [isOpen]);

  //Si se tienen los datos, se carga la respuesta y se llama a los submenús
  return isLoading ? (
    <div>Loading... x</div>
  ) :(
    <div className={ddStyles.dropdown_wrapper} onKeyUp={keyHandler}>
      <button 
        className={ddStyles.dropdown_activator}
        aria-haspopup="true"
        aria-controls={dropdownTitle}
        onClick={clickHandler}
        ref={activatorRef}
      >
        {dropdownTitle}{" "}
        {isOpen ? (
          <svg
            height="24"
            fill="rgb(70,70,70)"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m0 0h24v24h-24z" fill="none" />
            <path d="m7.41 15.41 4.59-4.58 4.59 4.58 1.41-1.41-6-6-6 6z" />
          </svg>
        ) : (
          <svg
            height="24"
            fill="rgb(70,70,70)"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m0 0h24v24h-24z" fill="none" />
            <path d="m7.41 8.59 4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
          </svg>
        )}
      </button>
      <ul
        ref={dropdownListRef}
        className={`${ddStyles.dropdown_item_list} ${isOpen ? ddStyles.active : ""} `}
      >
        {menus.map((item, index) => {
          console.log("-------------------------------"+item)
          return (
            <li className={ddStyles.item_list} key={index} >
              <NavBarSubMenu dropdownTitle={item} titles={datos} SetChart={(e) => {SetChart(e)}} />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default NavBarMenu;