import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import DescargaPlanillas from "../../components/DescargaPlanillas";
import UserManager from "../../functions/SessionManager";


export default function DescargarArchivo( props ) {
  const { setScreen } = props;
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  if (!UserManager.isAllowed()){
    setScreen("NotConected");
  }

  // ACA AGREGAR CODE DE SKIN-VIEWPORT
  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      {/*<div className="main main-app p-3 p-lg-4"> */}
        <Row className="g-1 px-1 border">
          <Col sm="12" md="12" xl="12" >
            <div className="h-100 p-2 ">
              <Card className="card-one">
                <Card.Body>
                  <DescargaPlanillas />
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      {/*</div>*/}
    </React.Fragment>
  );
}
