import React, { useState, useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Ejemplo.css';
import axios from 'axios'
import ComplexApexChart from '../components/charts/ApexChart';
import ApexChartExample from '../components/charts/ApexChart2';
import IndicadorBarChart from '../components/charts/IndicadorBarChart';
import IndicadorHorizontalBarChart from '../components/charts/IndicadorHorizontalBarChart';
import MultiAxisLineChart from '../components/charts/IndicadorMultiAxis';
import ComboChart from '../components/charts/IndicadorComboChart';
import AcreditadosPorSedeChart from '../components/charts/IndicadorDonaChart';
import MultipleDoughnutChart from '../components/charts/IndicadorMultiPie';
import RadarChartExample from '../components/charts/IndicadorRadarChart';
import ChartCard from '../components/charts/ChartCard';
// Componente Tarjeta personalizado


const BingStyleCard = ({ title, description, imageUrl, link, logoUrl }) => {
  const [isHovered, setIsHovered] = useState(false);
  

  const descriptionStyle = {
    color: 'white',
    textDecoration: isHovered ? 'underline' : 'none',
  };

  const cardStyle = {
    width: '16rem',
    height: '15.5rem',
    marginBottom: '0rem',
    position: 'relative',
    overflow: 'hidden',
  };

  const imageStyle = {
    width: '100%',
    height: '10rem',
    objectFit: 'cover',
    objectPosition: 'center',
  };

  // Asegúrate de que la descripción se ajuste directamente debajo de la imagen
  const textStyle = {
    position: 'absolute',
    top: '10rem', // Posiciona el texto justo debajo de la imagen
    width: '100%',
    padding: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fondo semi-transparente para la descripción
    color: 'white',
  };

  const titleStyle = {
    display: 'flex',
    alignItems: 'center', // Centra el logo y el título verticalmente
    gap: '0.5rem', // Espacio entre el logo y el título
  };

  const logoStyle = {
    width: '25px', // Ajusta según el tamaño deseado para el logo
    height: '21px', // Ajusta según el tamaño deseado para el logo
    backgroundColor: 'transparent', // Asegura que el fondo sea transparente
    border: 'none', // Elimina cualquier borde predeterminado
    borderRadius: '10px',
    marginBottom: '6px'
  };


  return (
    <Card className="m-2" style={cardStyle}>
      <Card.Img variant="top" src={imageUrl} style={imageStyle} />
      <div style={textStyle}>
        <div style={titleStyle}>
          <img src={logoUrl} style={logoStyle} />
          <Card.Title style={{ fontSize: '17px' }}>{title}</Card.Title>
        </div>
        <Card.Text as="div" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
          <a href={link} target="_blank" rel="noopener noreferrer" style={descriptionStyle}>
            {description}
          </a>
        </Card.Text>
      </div>
    </Card>
  );
};

export default function Ejemplo(props) {
  const [charts, setCharts] = useState([]);
  // const chartTypes = ['consolidadoxgenero'];
  const chartTypes = ['proyecto-facultad-sexo', 'historico-facultad', 'monto-ua', 'historico-proyecto-sede', 'historico-monto-sede','aportes-proyecto', 'financiamiento-interno', 'radarchart'];


  const handleUpdateAllClick = async () => {
    try {
        for (const type of chartTypes) {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}charts/update-charts`, {
                typeDataUniversity: type
            });
            console.log(`Update successful for ${type}:`, response.data);
        }
        alert('All charts updated successfully!');
    } catch (error) {
        console.error('Failed to update charts:', error);
        alert('Failed to update charts');
    }
};

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}charts/random-chart`)
        .then(response => {
            setCharts(response.data); // Asumiendo que la API devuelve un array de gráficos
            console.log("GRAFICOS ALEATORIOS", response.data);
        })
        .catch(error => console.error('Error fetching charts:', error));
}, []);
//   const dataBarr = [
//     { name: "Grupo A", value: 4000 },
//     { name: "Grupo B", value: 3000 },
//     { name: "Grupo C", value: 2000 },
//     { name: "Grupo D", value: 5000 }
// ];

// const chartData = {
//   categories: ['Proyecto 1', 'Proyecto 2', 'Proyecto 3', 'Proyecto 4'],
//   values: [10, 41, 35, 51]
// };

const dataTree = {
  name: "Top Level",
  children: [
    { 
      name: "Level 2: A",
      children: [
        { name: "Level 3: A1" },
        { name: "Level 3: A2" }
      ] 
    },
    { name: "Level 2: B" }
  ]
};

  return (
    <React.Fragment>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="#">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Ejemplo</li>
            </ol>
            <h4 className="main-title mb-0">Ejemplo</h4>
          </div>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          {charts.map((chart, index) => (
            <ChartCard key={index} chart={chart} />
          ))}
        </div>
        {/* Ajuste aquí: Aplica Flexbox al contenedor de las tarjetas */}

        <div>
        {/* <button onClick={handleUpdateAllClick}>
            Update Charts
        </button> */}
          <IndicadorBarChart></IndicadorBarChart>
          <IndicadorHorizontalBarChart></IndicadorHorizontalBarChart>
          <MultiAxisLineChart></MultiAxisLineChart>
          <ComboChart></ComboChart>
          <AcreditadosPorSedeChart></AcreditadosPorSedeChart>
          {/* <MultipleDoughnutChart></MultipleDoughnutChart> */}
          <RadarChartExample></RadarChartExample>
        </div>
      </div>
    </React.Fragment>
  );
}
