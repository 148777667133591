import React, { useRef } from 'react';
import './charts.css';
import { Card, Button } from 'react-bootstrap';
import { Bar, Doughnut, Pie, Radar, Line, PolarArea } from 'react-chartjs-2';
import {
  barOptions,
  doughnutOptions,
  pieOptions,
  radarOptions,
  polarOpcions,
  lineOptions,
  stackedbarOptions
} from './ChartOptions';

const ChartCard = ({ chart, style }) => {
  const chartRef = useRef(null); // Ref para el gráfico

  const data = {
    labels: chart.labels,
    datasets: chart.datasets.map(dataset => ({
      ...dataset,
      backgroundColor: dataset.backgroundColor,
      borderColor: dataset.borderColor,
      borderWidth: dataset.borderWidth,
    }))
  };

  const getCardStyle = (type) => {
    switch (type) {
      case 'BarChart':
        return 'chart-card bar-chart';
      case 'StackedBarChart':
        return 'chart-card stacked-bar-chart';
      case 'DoughnutChart':
        return 'chart-card doughnut-chart';
      case 'PieChart':
        return 'chart-card pie-chart';
      case 'RadarChart':
        return 'chart-card radar-chart';
      case 'PolarAreaChart':
        return 'chart-card polar-chart-inte';
      default:
        return 'chart-card';
    }
  };

  const getChartComponent = (type) => {
    switch (type) {
      case 'BarChart':
        return <Bar ref={chartRef} data={data} options={barOptions(data.labels)} />;
      case 'DoughnutChart':
        return <Doughnut ref={chartRef} data={data} options={doughnutOptions} />;
      case 'PieChart':
        return <Pie ref={chartRef} data={data} options={pieOptions} />;
      case 'RadarChart':
        return <Radar ref={chartRef} data={data} options={radarOptions} />;
      case 'LineChart':
        return <Line ref={chartRef} data={data} options={lineOptions} />;
      case 'StackedBarChart':
        return <Bar ref={chartRef} data={data} options={stackedbarOptions(data.labels)} />;
      case 'PolarAreaChart':
        return <PolarArea ref={chartRef} data={data} options={polarOpcions} />;
      default:
        return <div>No chart available for type {type}</div>;
    }
  };

  const downloadImage = () => {
    const chartInstance = chartRef.current;
    if (chartInstance) {
      const chart = chartInstance.chartInstance || chartInstance; // Asegura obtener el objeto de gráfico
      const link = document.createElement('a');
      link.href = chart.toBase64Image();
      link.download = 'chart.png'; // Nombre del archivo a descargar
      link.click();
    }
  };

  return (
    <Card className={`m-2 ${getCardStyle(chart.type)}`} style={{
      ...style,
      boxShadow: '0 4px 4px rgba(0, 0, 0, 0.1)',
    }} >
      <div
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          lineHeight: '1.2',
          fontWeight: 'bold',
        }}
        title={chart.cHeader}
      >
        {chart.cHeader}
      </div>
      <Button
          onClick={downloadImage}
          className="btn-sm download-btn"
          style={{ position: 'absolute', top: '3px', right: '10px' }} // Posiciona el botón en la esquina superior derecha
          variant="light"
        >
          <i className="fas fa-download"></i> {/* Icono de FontAwesome */}
        </Button>
      <Card.Body style={{ position: 'relative' }}> {/* Añadido para posicionar el botón */}
        <div style={{ height: '100%', minHeight: '200px' }}>
          {getChartComponent(chart.type)}
        </div>
        {/* Botón de descarga pequeño con ícono, posicionado a la derecha */}

      </Card.Body>
    </Card>
  );
};

export default ChartCard;
