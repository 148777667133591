import { useEffect, useState } from 'react';
import axios from 'axios';
import useFetch from "react-fetch-hook";
var Qs = require('qs');

function containsObject(obj, list) {
    var x;
    for (x in list) {
        if (list.hasOwnProperty(x) && list[x] === obj) {
            return true;
        }
    }
  
    return false;
  }

const chartManager = (function() {

    const GetChartsInfo = ( Lista, setData ) => {
        const url = process.env.REACT_APP_API_URL+"charts/info";

        useEffect(() => {
            const params = { array : Lista }; 
            axios.get(url,{params,paramsSerializer: params => {
                return Qs.stringify(params)
            }}).then((response) => {
                setData(response.data);
            }).catch((error) => {
                console.log(error);
            })
        }, [Lista]); //Obs: Lista no necesariamente tiene el mismo orden de respuesta almacenado en Data por tanto update por key de response no por indice de Lista
    }
    
    const GetChartsId = ( x ) => {
        const url = process.env.REACT_APP_API_URL+"charts/"+x;
        const [data, setData] = useState(null);
        useEffect(() => {
           // const params = { array : Lista }; 
            axios.get(url).then(({data}) => {
          
                setData(data);
            })
        }, [x]);
        return data;
    }
    



    const GetChartsVector = ( Lista, setData) => {
        //const [data, setData] = useState(null);
        const [loading, setLoading] = useState(null);
        const [error, setError] = useState(null);
        const [resp, setRest] = useState(null);
        const url = process.env.REACT_APP_API_URL+"charts/lista/vector";
   
        useEffect(() => {
            setLoading('loading u2...')
            //setData(null);
            setError(null);
            setRest(null);

        }, []); // Only re-run the effect if count changes       
    

        useEffect(() => {
            
            const params = {array : Lista, headers: {
                "Access-Control-Allow-Origin": "*"
              } }; 
            axios.get(url,{params,paramsSerializer: params => {
                return Qs.stringify(params)
                }}).then((response) => {
                
                    setData(response.data);
                    setLoading(false);
                    response.data.content && setData(response.data.content);
                    response.content && setData(response.content);
                    response && setRest(response);
               
                }).catch(err => {
                    setLoading(false)
                    console.log(err)
                   
                    if (!err.response) {
                        // network error
                        this.errorStatus = 'Error: Network Error';

                        setError(this.errorStatus)  
                    } else {
                        this.errorStatus = err.response.data.message;
                        setError(this.errorStatus)
                    } 
                    console.log(error)   
                })
                    
              //  console.log(response.data) 
            }, [Lista,url,setData,error]);            
    } 

    const GetCharts = ( llamada ) => {
        const url = process.env.REACT_APP_API_URL+"charts/";
        const [data, setData] = useState(null);
        const [loading, setLoading] = useState(null);
        const [error, setError] = useState(null);
        const [resp, setRest] = useState(null);

        setLoading('loading u2...')
        setData(null);
        setError(null);
        setRest(null);
        useEffect(() => {
            //const params = { array : Lista }; 
            axios.get(url).then(res => {
                setData(res.data);
                setLoading(false);
                res.data.content && setData(res.data.content);
                res.content && setData(res.content);
                res && setRest(res);

            })
            .catch(err => {
                setLoading(false)
                setError('An error occurred. Awkward u2..')
                console.log(err)})
        }, [llamada,url]);

        return { data, loading, error, resp }

    }
    
    return {
        GetChartsInfo: GetChartsInfo,
        GetChartsVector: GetChartsVector,
        GetCharts: GetCharts,
        GetChartsId: GetChartsId
    }
})();
  
  export default chartManager;