import axios from 'axios';
import UserManager from '../functions/SessionManager';

const uploadfileManager = (function() {

    const PostSubirArchivo = ( setSalida, Archivo, Opcion ) => {
        const url = process.env.REACT_APP_API_URL+'uploadfile/';

        //useEffect(() => {
        axios.post(url, {dataFile: Archivo, typeFile: Opcion}, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            params : {
                UserID: UserManager.getID()}
            }
            ).then((response)=>{
                if (response.data.status === true){
                    setSalida(
                      {
                        style:"success",
                        text:"El archivo se subio correctamente y se ha generado la metadata de gráficos del motor de búsqueda. Ahora puede buscar la nueva data gráfica en el búscador",
                        error: []
                      }
                    )
                }
            }).catch((error) => {
                setSalida(
                    {
                      style:"danger",
                      text:(error.response.data.length == 1? "Se ha detectado un error al procesar el archivo: " : "Se han detectado "+error.response.data.length+" errores al procesar el archivo: "),
                      error: error.response.data
                    }
                  )
            });
        //}, []);
    }
    
    return {
        PostSubirArchivo: PostSubirArchivo,
    }
})();
  
  export default uploadfileManager;