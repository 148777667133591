const pdfgenerator = (function() {
    const GetPDF = async ( Lista ) => {
        const url = process.env.REACT_APP_API_URL+"generate-pdf";

        const elemento = document.createElement('iframe');
        elemento.setAttribute('src', url+"?array=["+(Lista.map((x)=>{return "\""+x+"\""})).toString()+"]");

        elemento.style.display = 'none';
        document.body.appendChild(elemento);
    }
    
    return {
      GetPDF: GetPDF,
    }
})();
  
export default pdfgenerator;