import React, { Component } from "react";

function DBMenuSidebar(props) {
    const { setScreen } = props;

    const SelectCargarDatos = () => {
        setScreen("CargarDatos");
    }

    const SelectDescargarDatos = () => {
        setScreen("DescargarDatos");
    }

    const SelectManejoDB = () => {
        setScreen("ManejoDB");
    }

    return (
        <React.Fragment>
            <div className="nav-group show">
                {/*<div className="nav-label">Dashboard</div>*/}
                <ul className="nav nav-sidebar">
                    <div className="nav-link has-sub" onClick={() => {SelectCargarDatos()}}><i className="ri-uninstall-line"></i> <span>Cargar datos</span></div>
                </ul>
                <ul className="nav nav-sidebar">
                    <div className="nav-link has-sub" onClick={() => {SelectDescargarDatos()}}><i className="ri-install-line"></i> <span>Descargar datos</span></div>
                </ul>
                <ul className="nav nav-sidebar">
                    <div className="nav-link has-sub" onClick={() => {SelectManejoDB()}}><i className="ri-clipboard-line"></i> <span>Manejo DB</span></div>
                </ul>
            </div>
        </React.Fragment>
    )
}


export default DBMenuSidebar;