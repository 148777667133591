import React, { useState ,useEffect} from "react";
import { Button, Card, Col, Form, Row, Alert } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import UserManager from "../functions/SessionManager";

import {MyEstadoGlobalContext} from '../layouts/MyEstadoGlobalContext';

export default function Ingreso(props) {
  const [User, SetUser] = useState();
  const [Password, SetPassword] = useState();
  const [Message, SetMessage] = useState(false);
  const [Allowed, SetAllowed] = useState(false);
  const navigate = useNavigate();


  const {origenIn,setOrigenIn} = props;

  const {mostrarWSE, setMostrarWSE} = React.useContext(MyEstadoGlobalContext);
  const {mostrarBD, setMostrarBD} = React.useContext(MyEstadoGlobalContext);
  const {mostrarProyectos, setMostrarProyectos} = React.useContext(MyEstadoGlobalContext);
  const {mostrarPI, setMostrarPI} = React.useContext(MyEstadoGlobalContext);
  const {mostrarHome, setMostrarHome} = React.useContext(MyEstadoGlobalContext);
  const {mostrarAyuda, setMostrarAyuda} = React.useContext(MyEstadoGlobalContext);

  useEffect(() => {
    //setOrigenIn(origenIn);
   // origenIn = 'Database' 
  //  setOrigenIn('Database')
  setMostrarWSE(false)
  setMostrarBD(true)
  setMostrarPI(false)
  setMostrarHome(false)
  setMostrarProyectos(false)
  setMostrarAyuda(false)
  }, [origenIn]);


  const CambioNombre = (e) => {
    SetUser(e.target.value);
  };
  const CambioContraseña = (e) => {
    SetPassword(e.target.value);
  };

  const IniciarSesion = async (e) => {
    e.preventDefault();

    var resp = await UserManager.TestSesion(User, Password);

    SetMessage(true);
    SetAllowed(resp);

    if (resp){
      setTimeout(() => {navigate("/dashboard/databasemenu")}, 1000);
    }
  };



  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header>
          <Link to="/" className="header-logo mb-4">Administración de Observatorio Prometea</Link>
          <Card.Title>Sign In</Card.Title>
          <Card.Text>Bienvenido(a), ingrese la información para continuar (obs: este panel es sólo para el administrador(a) de datos de Prometea.)</Card.Text>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={(e) => {IniciarSesion(e)}}>
            <div className="mb-4">
              <Form.Label >Email</Form.Label>
              <Form.Control onChange={(e) => CambioNombre(e)} type="text" placeholder="Ingrese su dirección de correo electrónico" />
            </div>
            <div className="mb-4">
              <Form.Label className="d-flex justify-content-between">Contraseña de administrador 
            {/*   <Link to="">Forgot password?</Link> */}
              </Form.Label>
              <Form.Control onChange={(e) => CambioContraseña(e)} type="password" placeholder="Ingrese su contraseña" />
            </div>
            {Message?
              (Allowed?
                <Alert variant="success">
                  Inicio de sesion correcto
                </Alert>
                :
                <Alert variant="danger">
                  El usuario o contraseña es incorrecto
                </Alert>
              )
              :
              ""
            }
            <Button type="submit" variant="primary" className="btn-sign">Sign In</Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  )
}