import React, { useRef } from 'react';
import './charts.css';
import { Card, Button } from 'react-bootstrap';
import { Bar, Doughnut, Pie, Radar, Line } from 'react-chartjs-2';
import {
  barOptions,
  doughnutOptions,
  pieOptions,
  radarOptions,
  lineOptions,
  stackedbarOptions
} from './ChartOptions';

const ChartCardGigante = ({ chart }) => {
  const chartRef = useRef(null);  // Usamos useRef para almacenar la referencia al gráfico

  const data = {
    labels: chart.labels,
    datasets: chart.datasets.map(dataset => ({
      ...dataset,
      backgroundColor: dataset.backgroundColor,
      borderColor: dataset.borderColor,
      borderWidth: dataset.borderWidth,
    }))
  };

  const downloadImage = () => {
    const chartInstance = chartRef.current; // Obtenemos la referencia al gráfico
    if (chartInstance) {
      const chart = chartInstance.chartInstance || chartInstance; // Obtenemos la instancia del gráfico
      const link = document.createElement('a');
      link.href = chart.toBase64Image();
      link.download = 'chart.png'; // Nombre del archivo a descargar
      link.click();
    }
  };

  const getChartComponent = (type) => {
    switch (type) {
      case 'BarChart':
        return <Bar ref={chartRef} data={data} options={barOptions(data.labels)} />;
      case 'DoughnutChart':
        return <Doughnut ref={chartRef} data={data} options={doughnutOptions} />;
      case 'PieChart':
        return <Pie ref={chartRef} data={data} options={pieOptions} />;
      case 'RadarChart':
        return <Radar ref={chartRef} data={data} options={radarOptions} />;
      case 'LineChart':
        return <Line ref={chartRef} data={data} options={lineOptions} />;
      case 'StackedBarChart':
        return <Bar ref={chartRef} data={data} options={stackedbarOptions(data.labels)} />;
      default:
        return <div>No chart available for type {type}</div>;
    }
  };

  return (
    <Card className="m-2 full-width-chart" style={{
      boxShadow: '0 4px 4px rgba(0, 0, 0, 0.1)',
    }}>
      <div className="chart-title">{chart.cHeader}</div>
      <Button
        onClick={downloadImage}
        className="btn-sm download-btn"
        style={{ position: 'absolute', top: '3px', right: '10px' }} // Posiciona el botón en la esquina superior derecha
        variant="light"
      >
        <i className="fas fa-download"></i> {/* Icono de FontAwesome */}
      </Button>
      <Card.Body>
        <div style={{ height: '100%', minHeight: '400px' }}>
          {getChartComponent(chart.type)}
        </div>
      </Card.Body>
    </Card>
  );
};

export default ChartCardGigante;
