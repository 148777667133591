import { useEffect } from 'react';
import axios from 'axios';
import UserManager from '../functions/SessionManager';

const systemvariablesManager = (function() {
    const GetTypeFileUpload = ( setListado ) => {
        const url = process.env.REACT_APP_API_URL+"systemVariables/typeFileUpload";

        useEffect(() => {
            axios.get(url, {params : {UserID: UserManager.getID()}}).then((response) => {
                setListado(response.data);
            });
        }, []);
    }
    
    return {
        GetTypeFileUpload: GetTypeFileUpload,
    }
})();
  
  export default systemvariablesManager;