import React from "react";
import { Button, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

import img1 from "../../assets/img/img1.jpg";

export default function NotConected() {
  return (
    <div className="d-flex align-items-center justify-content-center">
    {/*<div className="page-sign">*/}
      <Card className="card-sign">
      {/*<Card className="d-flex align-items-center justify-content-center">*/}
        <Card.Header className="d-flex flex-column align-items-center">
          <Link to="/" className="header-logo mb-4">Observatorio Prometea</Link>
          <Card.Title className="text-center">Contenido bloqueado</Card.Title>
          <Card.Text className="text-center">Necesitas estar identificado para acceder a esta funcion.</Card.Text>
        </Card.Header>
        <Card.Body className="d-flex flex-column align-items-center">
          {/*<Link to="/" className="card-thumb"><img src={img1} alt="" /></Link>*/}
          <Link to="/pages/ingreso" className="mb-4"><Button variant="primary" className="btn-sign">Ir a inicio de sesion</Button></Link>
        </Card.Body>
      </Card>
    </div>
  )
}