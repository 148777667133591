import React, { useState } from "react";
import uploadfileManager from "../../hooks/uploadfileManager";
import "./SubirCSV.css";

//Selector de archivos
function SubirCSV( props ) {
  const { Opcion, SetSalida } = props;
  const [Archivo, SetArchivo] = useState(null);  
  
  //Evento al seleccionar un archivo para subir
  const CambioArchivo = (e) => {
    SetArchivo(e.target.files[0]);
  };

  //Evento al hacer click en subir archivo
  const SubirArchivo = async (e) => {
      e.preventDefault();

      console.log("Archivo script");

      if (Archivo && Opcion) {
        uploadfileManager.PostSubirArchivo(SetSalida, Archivo, Opcion);
      }else{
        SetSalida(
          {
            style:"danger",
            text:"Se debe seleccionar un archivo y un tipo de archivo",
            error: []
          }
        )
      }
  };

  return ( 
    <>
      <form className="SubirCSV-form">
        <input className="form-control form-control-lg" accept=".csv" type="file" onChange={(e) => {CambioArchivo(e)}}/>
        <br/>
        <button onClick={(e) => {SubirArchivo(e)}} className="fw-medium btn btn-primary btn-lg w-30 mb-3">Subir archivo</button>
      </form>
    </>
  );
}

export default SubirCSV;