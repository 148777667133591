import { useState, useEffect } from "react";
import useFetch from "react-fetch-hook";

const SearchEngine = (querieInput) => {
  const [response, setResponse] = useState(null);

  const { isLoading, data, error } = useFetch(process.env.REACT_APP_API_URL+"charts/index/consulta="+querieInput);  
  
  if(error)
  {
   // alert('SE > Prometea no encontro resultados de la búsqueda. (a continuación un ejemplo de resultados ...')
    return     (<>
    <h3 className="card-value">Sin resultados ...</h3>
    {/*<h3 className="card-value">0.9:8</h3>
    <ProgressBar now={50} className="ht-5 mb-2" />
    <label className="fw-semibold text-dark mb-1">Quick Ratio Goal: 1.0 or higher</label>
    <p className="fs-sm text-secondary mb-0">Measures your Accounts Receivable / Current Liabilities</p>*/}
  </>)
  }
  else {

  return isLoading ? (
    <div>Loading........</div>
  ) : ( data );
  }
  
};

export default SearchEngine;