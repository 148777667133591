import React from "react";
import styled from "styled-components";
import './NavbarPrimaryColor.css'

function NavbarPrimaryColor(props) {
  const { placeholder, search, buscar, SetPantalla } = props;
  const [query, setQuery] = React.useState("");
  const ClickBoton = (e) => {
   // alert(e)
    SetPantalla(e);
  };

  return (
    <NavbarPrimaryColor1>
      
    {/* <InputGroupBaseTextMiddleMedium>
       <Search>{search}    </Search> 
    </InputGroupBaseTextMiddleMedium>
    */}
     
    <InputGroupBaseTextMiddleMedium> 
    <div className="searchInputs">
     <input type="text"
            placeholder={placeholder}
            value={query}
            onChange={(event) => setQuery(event.target.value)}/>
    </div>
      </InputGroupBaseTextMiddleMedium>
      <ButtonDefaultLight onClick={(e) => {ClickBoton(query)}}>
        <Buscar>{buscar}</Buscar>
      </ButtonDefaultLight>
     
    </NavbarPrimaryColor1>
  );
}

const NavbarPrimaryColor1 = styled.div`
  height: 38px;
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

const InputGroupBaseTextMiddleMedium = styled.div`
  width: 455px;
  display: flex;
  background-color: var(--concrete);
  border-radius: 4px;
  border: 2px solid;
  border-color: #679598;
`;

const Search = styled.div`
  margin-top: 11.5px;
  width: 53px;
  height: 19px;
  margin-left: 14px;
  font-family: var(--font-family-helveticaneue-regular);
  font-weight: 400;
  color:  #000000;
  font-size: var(--font-size-s);
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
`;

const ButtonDefaultLight = styled.div`
  margin-top: 1px;
  display: flex;
  padding: 8.5px 15px;
  align-items: flex-start;
  min-width: 82px;
  border-radius: 5px;
  border: 1px solid;
  border-color: #f8f9fa;
  transition: all 0.2s ease;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    transform: translate(0, -5px);
    box-shadow: 0px 12px 5px -8px rgba(0, 0, 0, 0.2);
  }
`;

const Buscar = styled.div`
  min-height: 19px;
  font-family: var(--font-family-helveticaneue-regular);
  font-weight: 400;
  color: var(--white);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  line-height: 16px;
  white-space: nowrap;
`;

export default NavbarPrimaryColor;
