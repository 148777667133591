import { useEffect } from 'react';
import axios from 'axios';
import UserManager from '../functions/SessionManager';

const dbmanagementManager = (function() {
    const GetDocData = async ( setDataTable, docName ) => {
        const url = process.env.REACT_APP_API_URL+ "dbmanagement/info/" + docName;
    
        useEffect(() => {
            axios.get(url, {params : {UserID: UserManager.getID()}}).then((response) => {
                setDataTable(response.data.Files);
            });
        }, [])
    }

    const SaveDocData = async ( docName, idName, setNotification ) => {
        const url = process.env.REACT_APP_API_URL+ "dbmanagement/save/" + docName;
    
        axios.get(url, {params : {UserID: UserManager.getID(), IdName: idName}}).then((response) => {
            //console.log(response);
            setNotification({
                Status: response.status,
                Error: response.data.error
            });
        });
    }

    const LoadDocData = async ( docName, idName, setNotification ) => {
        const url = process.env.REACT_APP_API_URL+ "dbmanagement/load/" + docName;
    
        axios.get(url, {params : {UserID: UserManager.getID(), IdName: idName}}).then((response) => {
            //console.log(response);
            setNotification({
                Status: response.status,
                Error: response.data.error
            });
        });
    }
    
    return {
      GetDocData: GetDocData,
      SaveDocData: SaveDocData,
      LoadDocData: LoadDocData
    }
})();
  
  export default dbmanagementManager;