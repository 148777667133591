import React, { useEffect, useState, Component } from "react";
import { Button, Card, Col, Row, Nav, Table, Form } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import { Doughnut } from 'react-chartjs-2';
import UserManager from "../../functions/SessionManager";
import formsManager from "../../hooks/formsManager";
import dbmanagementManager from "../../hooks/dbmanagementManager";
import "../../assets/css/animations.css";

export default function DatabaseManagementSpec( props ) {
  const { setScreen, DBMOptions, setNotification } = props;
  const [DataExample, SetDataExample] = useState(null);
  const [DataTable, SetDataTable] = useState(null);
  const [FileName, setFileName] = useState("");

  if (!UserManager.isAllowed()){
    setScreen("NotConected");
  }
  if (DBMOptions.DBName === ""){
    setScreen("");
  }

  formsManager.GetSpecTemporalData(SetDataExample, DBMOptions.DBName);
  dbmanagementManager.GetDocData(SetDataTable, DBMOptions.DBName);

  console.log(FileName);

  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  const LoadData = ( idName ) => {
    dbmanagementManager.LoadDocData(DBMOptions.DBName, idName, setNotification);
    setScreen("Notification");
  }
  
  const SaveData = () => {
    dbmanagementManager.SaveDocData(DBMOptions.DBName, FileName, setNotification);
    setScreen("Notification");
  }

  const ChangeFileName = (e) => {
    setFileName(e.target.value);
  }

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return DataExample && DataTable? (
    <React.Fragment>
        <Row className="g-3">
          <DatabaseDocumentData data={DataExample}/>
          <Col md="7" lg="6" xl="4">
          </Col>
        </Row>
        <Table bordered className="w-60 mb-4">
            <thead>
                <tr>
                    <th scope="col">Nombre</th>
                    <th scope="col">Fecha</th>
                    <th scope="col">Cantidad columnas</th>
                    <th scope="col">Peso archivo</th>
                    <th scope="col">Opcion</th>
                </tr>
            </thead>
            <tbody>
                {DataTable.map((i, j) => {
                    return <tr key={"ErrorMessages-index-"+j}>
                                <th scope="row">{i.IDName}</th>
                                <td>{(new Date(i.Date)).toLocaleString("es-CL")}</td>
                                <td>{i.FileCount}</td>
                                <td>{i.FileSize/1000} kb</td>
                                <td><Button onClick={() => {LoadData(i.IDName)}}>Cargar datos</Button></td>
                            </tr>
                })}
                <tr>
                    <th scope="row"><Form.Control className="form-control-sm" type="text" onChange={(e) => {ChangeFileName(e)}} /></th>
                    <td><Button onClick={(e) => { SaveData(e) }}>Guardar datos</Button></td>
                </tr>
            </tbody>
        </Table>
    </React.Fragment>
  )
  :
  ""
};


class DatabaseDocumentData extends Component {
  DataTemporal = [];
  ColectionSize;
  ColectionCount;
  ColectionName;

  states = {
    hover: {
      filter: {
        type: 'none'
      }
    },
    active: {
      filter: {
        type: 'none'
      }
    }
  };

  optionOne = {
    chart: {
      parentHeightOffset: 0,
      stacked: true,
      toolbar: { show: true }
    },
    grid: {
      borderColor: 'rgba(72,94,144, 0.07)',
      padding: {
        top: -20,
        left: 0,
        right: 0,
        bottom: 0
      },
      yaxis: {
        lines: { show: true },
      }
    },
    colors: ['#506fd9', '#c8ccd4'],
    plotOptions: {
      bar: { columnWidth: '90%' }
    },
    stroke: {
      curve: 'straight',
      lineCap: 'square',
      width: 0
    },
    xaxis: {
      min: 0,
      type: 'numeric',
      tickAmount: 6,
      decimalsInFloat: 0,
      labels: {
        style: { fontSize: '11px' }
      }
    },
    yaxis: {
      show: false
    },
    states: this.states,
    dataLabels: { enabled: false },
    tooltip: { enabled: true },
    fill: { opacity: 1 },
    legend: { show: false }
  };

  constructor(props) {
    const { data } = props;
    var date = new Date();
    super();

    this.DataTemporal = data.TemporalData;
    this.ColectionCount = data.ColectionCount;
    this.ColectionSize = data.ColectionSize/1000;
    this.ColectionName = data.ColectionName;

    this.state = { 
      SelectedYear: date.getFullYear(),
      SelectedMonth: date.getMonth(),
      CompleteTable: []
    };

    this.UpdateTable();
  }

  async componentDidMount() {
    this.UpdateTable();
  }

  UpdateTable() {
    const { 
      SelectedYear,
      SelectedMonth,
      CompleteTable 
    } = this.state;

    var date = new Date();
    var TempTable = [];

    if (SelectedMonth == date.getMonth() && SelectedYear == date.getFullYear()){
      var CantDias = date.getDate();
      var num = [...Array(CantDias).keys()];
      for (let i in num){
        let DataAct = this.DataTemporal.find((e) => 
          {
            return e.year == SelectedYear && e.month == SelectedMonth && Number(e.day) == Number(i)+1;
          }
        );
        TempTable.push(DataAct == undefined? [Number(i)+1, 0] : [Number(i)+1, DataAct.value]);
      }
    }else {
      var CantDias = new Date(SelectedYear, SelectedMonth, 0).getDate();
      var num = [...Array(CantDias).keys()];
      for (let i in num){
        const DataAct = this.DataTemporal.find((e) => 
          {
            return e.year == SelectedYear && Number(e.month) == Number(SelectedMonth) && Number(e.day) == Number(i)+1;
          }
        );
        TempTable.push(DataAct == undefined? [Number(i)+1, 0] : [Number(i)+1, DataAct.value]);
      }
    }

    this.setState({
      SelectedYear: SelectedYear,
      SelectedMonth: SelectedMonth,
      CompleteTable: TempTable
    });
  }

  PrevMonth() {
    const { 
      SelectedYear,
      SelectedMonth,
      CompleteTable 
    } = this.state;

    let MesAct = SelectedMonth;
    let AñoAct = SelectedYear;

    MesAct = MesAct-1;
    if (MesAct < 0){
      AñoAct = AñoAct-1;
      MesAct = 11;
    }

    this.setState({
      SelectedYear: AñoAct,
      SelectedMonth: MesAct,
      CompleteTable: CompleteTable
    }, () => {this.UpdateTable()});
    
    //this.UpdateTable();
  }

  NextMonth() {
    const { 
      SelectedYear,
      SelectedMonth,
      CompleteTable 
    } = this.state;

    let MesAct = SelectedMonth;
    let AñoAct = SelectedYear;

    MesAct = MesAct+1;
    if (MesAct > 11){
      AñoAct = AñoAct+1;
      MesAct = 0;
    }

    this.setState({
      SelectedYear: AñoAct,
      SelectedMonth: MesAct,
      CompleteTable: CompleteTable
    }, () => {this.UpdateTable()});
  }

  render() {
      const { 
        SelectedYear,
        SelectedMonth,
        CompleteTable 
      } = this.state;
      const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
      return (
      <>
        <Col xs="12" sm="6" xl="2">
          <Card className="card-one">
            <Card.Body className="p-3 d-flex flex-column align-items-center justify-content-around">
              <div className="mb-1 text-primary ti--3"><i className="ri-file-text-line fs-48 lh-1"></i></div>
              <div className="mb-1">
                  <h6 className="fw-semibold text-dark mb-1">{this.ColectionName}</h6>
                  <p className="fs-xs mb-0 text-secondary"><span className="ff-numerals">{this.ColectionCount}</span> Archivos</p>
                  <div className="d-flex justify-content-between ff-numerals fs-xs fw-medium">
                  <span>{this.ColectionSize} kB</span>
                  </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl="10">
          <Card className="card-one">
            <Card.Header>
              <Card.Title as="h6">Informacion {this.ColectionName}</Card.Title>
              <Nav className="nav-icon nav-icon-sm ms-auto">
                <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
              </Nav>
            </Card.Header>
            <Card.Body className="p-3">
              <Row className="g-3">
                <Col sm="3" md="2" className="d-flex flex-column justify-content-around">
                  <Row className="g-3">
                    <Col xs="4" sm="4" className="hover-animation-up transition-2">
                      <i className="ri-arrow-drop-left-line fs-48 lh-1" onClick={(e) => {this.PrevMonth()}}></i>
                    </Col>
                    <Col xs="4" sm="4" className="d-flex flex-column align-items-center">
                      <h2 className="card-value ls--1 mb-1">{SelectedYear}</h2>
                      <label className="card-label fw-medium text-secondary fs-sm fs-sm-normal">{months[SelectedMonth]}</label>
                    </Col>
                    <Col xs="4" sm="4" className="hover-animation-up transition-2">
                      <i className="ri-arrow-drop-right-line fs-48 lh-1" onClick={(e) => {this.NextMonth()}}></i>
                    </Col>
                  </Row>
                </Col>
                <Col sm="9" md="10">
                  <ReactApexChart series={[{data: CompleteTable}]} options={this.optionOne} type="bar" height={200} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </>
      )
  }
}