import React from "react";
import { Link } from "react-router-dom";
import anid from "./img/static/ANID.png";
import ines from "./img/static/logo_ines.png";
import UA from "./img/static/UA.png";
export default function Footer() {
  return (
  /*   <div style={{ backgroundImage: `url(${background2})` }}>  */
    <div className="main-footer">
      <img src={UA} width="150" height="110"/>
      <img src={ines} width="150" height="110"/>
      
      <span>
        <img src={anid} width="150" height="110"/>
    
        
        {/* Created by: <Link to="http://neurovision.ai" target="_blank">neurovision</Link>*/}
        </span> 
        <span>&copy; 2023. Universidad Autónoma de Chile - InES Género </span>
    </div>
/*     </div> */
    
  )
}