import React, { useEffect, useState } from "react";
import { Accordion, Button, Card, Col, Row, Image} from "react-bootstrap";
import imgSearch from "./images/buscador.jpg";
import Zoom from 'react-medium-image-zoom'
import res from "./images/resultados.jpg";
import check from "./images/check.jpg";
import reporte from "./images/reporte.jpg";


import skin1 from "./images/skin1.jpg";
import skin2 from "./images/skin2.jpg";
import skin3 from "./images/skin3.jpg";

import login from "./images/login.jpg";
import carga from "./images/carga.jpg";
import descarga from "./images/descargas.jpg";

import Footer from "../layouts/Footer";

import {MyEstadoGlobalContext} from '../layouts/MyEstadoGlobalContext';
import CardGroup from 'react-bootstrap/CardGroup';

export default function Ayuda(props) {



  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const {origenIn,setOrigenIn} = props;

  const {mostrarWSE, setMostrarWSE} = React.useContext(MyEstadoGlobalContext);
  const {mostrarBD, setMostrarBD} = React.useContext(MyEstadoGlobalContext);
  const {mostrarProyectos, setMostrarProyectos} = React.useContext(MyEstadoGlobalContext);
  const {mostrarPI, setMostrarPI} = React.useContext(MyEstadoGlobalContext);
  const {mostrarHome, setMostrarHome} = React.useContext(MyEstadoGlobalContext);
  const {mostrarAyuda, setMostrarAyuda} = React.useContext(MyEstadoGlobalContext);

  useEffect(() => {
    //setOrigenIn(origenIn);
   // origenIn = 'Database' 
  //  setOrigenIn('Database')
  setMostrarWSE(false)
  setMostrarBD(false)
  setMostrarPI(false)
  setMostrarHome(false)
  setMostrarProyectos(false)
  setMostrarAyuda(true)
  }, [origenIn]);


  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }



  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>

    <div className="main main-app p-3 p-lg-4">
    <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            Bienvenid@s a la sección de ayuda del observatorio Prometea
         </ol>
  
        </div>
{/*           <div className="d-flex gap-2 mt-3 mt-md-0">
          <Button variant="" className="btn-white d-flex align-items-center gap-2">
            <i className="ri-printer-line fs-18 lh-1"></i>Print
          </Button>
          <Button variant="primary" className="d-flex align-items-center gap-2">
            <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generate<span className="d-none d-sm-inline"> Report</span>
          </Button>
        </div>  */}
      </div>

      <CardGroup>         
    
<Col xl="12">
<Row className="g-3 pt-3 pe-2">    


            <Col xs="12"  md="6">      
            <Card className="card-one">    <span className="square border ">   
            <Card.Header>
          <Card.Title as="h6">Uso del motor de búsqueda</Card.Title>
          </Card.Header>             
              <Card.Body className="p-3 pt-2  text-dark">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Interfaz de búsqueda</Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-2"><strong>Solo debes ingresar los términos de interes a tráves del panel de búsqueda.</strong></p>
                    <p className="mb-0">Por ejemplo ingresa la palabra proyectos y presiona la tecla enter.</p>
                    <Zoom>
                    <Image src={imgSearch} fluid={true} alt="..." />
                    </Zoom>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Despliegue de resultados</Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-2"><strong>Si existen gráficos con las palabras búscadas se desplegará un ranking de resultados</strong></p>
                    <p className="mb-0">Al hacer clic en cada resultado se desplegará un gráfico que puedes seleccionar para que puedas construir tu reporte en pdf.</p>
                    <Zoom>
                    <Image src={res} fluid={true} alt="..." />
                    </Zoom>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Card.Body> </span>
            </Card>              
            </Col>


            <Col xs="12"  md="6">      
            <Card className="card-one"><span className="square border ">   
            <Card.Header>
          <Card.Title as="h6">Impresión de reportes de búsqueda</Card.Title>

          </Card.Header>                  
              <Card.Body className="p-3 pt-2">     
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Selección de gráficos de reporte</Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-2"><strong>Presiona sobre las casillas de los gráficos de ínteres.</strong></p>
                    <p className="mb-0">Esto le indicará a Prometea que lo incluya en la lista de gráficos del reporte.</p>
                    <Zoom>
                    <Image src={check} fluid={true} alt="..." />
                    </Zoom>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Generar reporte y descarga de pdf</Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-2"><strong>Presiona el boton de generar reporte de la zona superior derecha.</strong></p>
                    <p className="mb-0">Espera unos segundos, se generará el reporte y se descargará automáticamente.</p>
                    <Zoom>
                    <Image src={reporte} fluid={true} alt="..." />
                    </Zoom>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Card.Body> </span>
            </Card>
            </Col>
           
     
            <Col xs="12" md="6">      
  <Card className="card-one">     <span className="square border ">         <Card.Header>
          <Card.Title as="h6">Administración</Card.Title>

          </Card.Header>             
    <Card.Body className="p-3 pt-4">
    <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Ingreso </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-2"><strong>Ingresa los datos proporcionados por el administrador de Prometea.</strong></p>
                    <p className="mb-0">Es importante mencionar que el acceso a ésta sección es restringida.</p>
                    <Zoom>
                    <Image src={login} fluid={true} alt="..." />
                    </Zoom>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Carga de datos y generación automática de metadata</Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-2"><strong>Selecciona la planilla de datos a ser cargada en la base de datos.</strong></p>
                    <p className="mb-0">Esta acción actualizará los datos almacenado y de manera automática se generará metadata, además de los gráficos a incluir en el motor de búsqueda de Prometea.</p>
                    <Zoom>
                    <Image src={carga} fluid={true} alt="..." />
                    </Zoom>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Descarga de datos</Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-2"><strong>Solo tienes que seleccionar la planilla de datos a descargar.</strong></p>
                    <p className="mb-0">Una vez presionado el boton de descarga, esta se realizará de manera automática.</p>
                    <Zoom>
                    <Image src={descarga} fluid={true} alt="..." />
                    </Zoom>
                  </Accordion.Body>
                </Accordion.Item>
             
              </Accordion>
            </Card.Body>
            </span>
            </Card>
            </Col>

            <Col xs="12" md="6">      
  <Card className="card-one">     <span className="square border ">                 <Card.Header>
          <Card.Title as="h6">Personalización de interfaz</Card.Title>

          </Card.Header>              
    <Card.Body className="p-3 pt-4">
    <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Selección de estilo general </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-2"><strong>Activa el menú de interfaz presionando el icono que está en la esquina superior derecha de tu pantalla.</strong></p>
                    <p className="mb-0">En la sección SKIN puedes seleccionar Light (iluminado) o Dark (oscuro).</p>
                    <Zoom>
                    <Image src={skin1} fluid={true} alt="..." />
                    </Zoom>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Personalización de menu</Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-2"><strong>Aquí usas la sección SIDEBAR SKIN.</strong></p>
                    <p className="mb-0">Al presionar tu selección se modificará el color del menu lateral izquierdo de tu pantalla (en telefonos moviles se oculta por defecto, pero lo puedes activar facilmente haciendo clic en la esquina superior izquierda de tu pantalla.</p>
                    <Zoom>
                    <Image src={skin2} fluid={true} alt="..." />
                    </Zoom>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Ocultamiento de menú</Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-2"><strong>Solo presiona el icono de tres barras.</strong></p>
                    <p className="mb-0">Este icono esta ubicado en la esquina superior izquierda al lado de la sección de búsqueda.</p>
                    <Zoom>
                    <Image src={skin3} fluid={true} alt="..." />
                    </Zoom>
                  </Accordion.Body>
                </Accordion.Item>
              
              </Accordion>
            </Card.Body>
            </span>
            </Card>
            </Col>
            {/* <Col xs="12" md="6">       
            <Card className="card-one">           <span className="square border">    
              <Card.Body className="p-3 pt-4">
              <div className="d-flex flex-column align-items-center">           
         <label className="card-value fs-16 mb-2 ls--1">Gráficos de datos</label>
         <p className="text-secondary fs-sm mb-0">Puedes descargar un reporte en pdf desde nuestro motor de búsqueda de gráficos.</p>
              
       </div>



            </Card.Body>  </span> 
            </Card>
            </Col> */}


      </Row>

</Col>





</CardGroup>
      <Footer />
    </div>
  </React.Fragment>
)
}