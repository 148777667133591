import { Col, Row } from 'react-bootstrap';
import DinamicElement from '../DinamicElement';

//Fila de graficos pertenecientes a una pagina
const RowCharts = ({updateSelectedChart, ...props}) => {
    return ( 
        (props.charts.length===0)?<Row><Col xs="18"></Col></Row>:
        <Row>
            {            
            props.charts.map(v =>                 
                <Col xs={v.cols.xs} md={v.cols.md} className="d-flex align-items-stretch">
                    {v.chartData===undefined || v.chartData==="" ? "" : <DinamicElement dinamicContent={v.chartData} updateSelectedChart={updateSelectedChart} />}
                </Col>
                )
            }
        </Row>

     );
}

export default RowCharts;