import React from 'react';
import ReactDOM from 'react-dom/client';
//#import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import App from './App';

//original
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <>
    <App />
    <div className="main-backdrop"></div>
    </>
   // </React.StrictMode> 



);
//ReactDOM.render(<App /> , document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
