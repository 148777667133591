import React, { useEffect, useState } from "react";
import { Button, Card, Col, Nav, ProgressBar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { dp3 } from "../data/DashboardData";
import UserManager from "../functions/SessionManager";
import DBMenuSidebar from "./DatabaseMenu/DBMenuSidebar";
import SubirArchivo from "./DatabaseMenu/SubirArchivo";
import DescargarArchivo from "./DatabaseMenu/DescargarArchivo";
import DatabaseManagement from "./DatabaseMenu/DatabaseManagement";
import NotConected from "./DatabaseMenu/NotConected";
import DatabaseManagementSpec from "./DatabaseMenu/DatabaseManagementSpec";
import NotificationScreen from "./DatabaseMenu/NotificationScreen";

 import {MyEstadoGlobalContext} from '../layouts/MyEstadoGlobalContext';
// const {mostrar, setMostrar} = React.useContext(MyEstadoGlobalContext);


export default function DatabaseMenu(props) { 
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);
  const [Screen, setScreen] = useState("");
  //const [bandera, setBandera] = useState(false);

  const {origenIn,setOrigenIn} = props;
  const {mostrarWSE, setMostrarWSE} = React.useContext(MyEstadoGlobalContext);
  const {mostrarBD, setMostrarBD} = React.useContext(MyEstadoGlobalContext);
  const {mostrarProyectos, setMostrarProyectos} = React.useContext(MyEstadoGlobalContext);
  const {mostrarPI, setMostrarPI} = React.useContext(MyEstadoGlobalContext);
  const {mostrarHome, setMostrarHome} = React.useContext(MyEstadoGlobalContext);
  const {mostrarAyuda, setMostrarAyuda} = React.useContext(MyEstadoGlobalContext);

  useEffect(() => {
    //setOrigenIn(origenIn);
   // origenIn = 'Database' 
  //  setOrigenIn('Database')
  setMostrarWSE(false) 
  setMostrarPI(false)
  setMostrarHome(false)
  setMostrarAyuda(false)  
  setMostrarProyectos(false)
  
  setMostrarBD(true)
  }, [origenIn]);

  const [bandera, setBandera] = useState(false);

 

  const [DBMOptions, setDBMOptions] = useState({
    DBName: "",
  })
  const [Notification, setNotification] = useState({
    Status: 0,
    Error: ""
  })


  //let Q = props.origenIn

/*   useEffect(() => {
    //setOrigenIn(origenIn);
   // origenIn = 'Database' 
  //  setOrigenIn('Database')
  setMostrarWSE(false)
  //setMostrarPI(false)
  //setMostrarProyectos(false)
  //alert("En usefect DB")
  }, [origenIn,setMostrarWSE]);
 */
  // useEffect(() => {
  //   //setOrigenIn(origenIn);
    
  //  // setBandera(true)
  // }, [origenIn]);

  const GetScreen = () => {
    switch(Screen){
        case "CargarDatos":
            return <SubirArchivo setScreen={(e) => {setScreen(e)}}/>
        case "DescargarDatos":
            return <DescargarArchivo setScreen={(e) => {setScreen(e)}}/>
        case "ManejoDB":
            return <DatabaseManagement setScreen={(e) => {setScreen(e)}} setDBMOptions={(e) => {setDBMOptions(e)}}/>
        case "ManejoDBSpec":
            return <DatabaseManagementSpec setScreen={(e) => {setScreen(e)}} DBMOptions={DBMOptions} setNotification={setNotification}/>
        case "Notification":
            return <NotificationScreen setScreen={(e) => {setScreen(e)}} Notification={Notification}/>
        case "NotConected":
            return <NotConected/>
        default:
            break;
    }
  }

  // ACA AGREGAR CODE DE SKIN-VIEWPORT
  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      <div className="main main-app p-3 p-lg-4"> 
         <div className="d-md-flex align-items-center justify-content-between mb-4">
        {/*  <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="#">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Proyectos</li>
            </ol>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button variant="" className="btn-white d-flex align-items-center gap-2">
              <i className="ri-printer-line fs-18 lh-1"></i>Imprimir
            </Button>
            <Button variant="primary" className="d-flex align-items-center gap-2">
              <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generate<span className="d-none d-sm-inline"> Report</span>
            </Button>
          </div>*/}
        </div> 
        <Row className="g-1 px-1 border">
          <Col sm="4" md="4" xl="2" >
            <Card className="card-one">

              <Card.Body>
                <DBMenuSidebar setScreen={(e) => {setScreen(e)}}/>
              </Card.Body>
            </Card>
          </Col>
          <Col sm="8" md="8" xl="10" >
            <Card className="card-one">
              <Card.Body>
                  { GetScreen() }
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* <Footer /> */}
      </div>
    </React.Fragment>
  );
}